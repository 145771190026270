.page {
    text-align: center;
    background: url('../../assets/home-background-2.gif') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.small-text {
    font-size: 10px;
}

a {
    text-decoration: none !important;
    cursor: pointer !important;
}

a:hover {
    color: #5d8fd8 !important;
    cursor: pointer !important;
}

/* header css */



/* body css */

.vh-90 {
    min-height: 90vh;
}

.border-black {
    border: 2px solid #424651;
}

.border-black-half {
    border-top: 2px solid #424651;
    border-bottom: 2px solid #424651;
    border-right: 1px solid #424651;
    border-left: 1px solid #424651;
}

.top-of-page-cont {
    /* background-color: #424651; */
    padding: 30px !important;
    border-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.heading-text {
    font-size: 8vh;
    font-weight: 900;
    color: #424651;
    text-shadow: 0px 0px 5px white;
}

.heading-sub-text {
    font-size: 5vh;
    font-weight: 500;
    color: #424651;
    text-shadow: 0px 0px 5px white;
}

.sign-up-row {
    display: flex;
    justify-content: end;
    margin-top: 30px;
}

.sign-up-cont {
    max-width: 600px;
    background-color: #78C3FB;
    box-shadow: #42465160 0px 2px 3.84px;
    padding: 45px !important;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sign-up-cont-dark {
    width: 100%;
    max-width: 600px;
    background-color: #424651;
    box-shadow: #42465160 0px 2px 3.84px;
    padding: 45px !important;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sign-up-text {
    font-weight: 900;
    color: white;
    text-shadow: 0px 0px 2px #424651;
}

.sign-up-input {
    width: 100%;
    box-shadow: rgb(0 0 0 / 22%) 0px 1px 2.22px;
    border-radius: 6px;
    border-width: 0;
    padding: 12.5px 15px;
}

.sign-up-button {
    width: 100%;
    background-color: #424651;
    box-shadow: rgb(0 0 0 / 22%) 0px 1px 2.22px;
    border-radius: 6px;
    border-width: 0;
    padding: 12.5px 15px;
    cursor: pointer;
}

.sign-up-button-sm {
    background-color: #424651;
    box-shadow: rgb(0 0 0 / 22%) 0px 1px 2.22px;
    border-radius: 6px;
    border-width: 0;
    padding: 5px 15px;
    cursor: pointer;
}

.sign-up-button-sm-clear {
    background-color: white;
    border-radius: 6px;
    border-width: 0;
    padding: 5px 10px;
    margin-right: 15px;
    cursor: pointer;
}

.sign-up-button-blue {
    width: 100%;
    background-color: #78C3FB;
    box-shadow: rgb(0 0 0 / 22%) 0px 1px 2.22px;
    border-radius: 6px;
    border-width: 0;
    padding: 12.5px 15px;
    cursor: pointer;
}

.sign-up-button-text {
    color: white;
    font-weight: 900;
}

.sign-up-button-text-dark {
    color: #424651;
    font-weight: 900;
}

.sign-up-button-create {
    width: 200px;
    background-color: white;
    box-shadow: rgb(0 0 0 / 22%) 0px 1px 2.22px;
    border-radius: 6px;
    border-width: 0;
    padding: 12.5px 15px;
    cursor: pointer;
}

@media(max-width: 1000px) {
    .header {
        padding: 0 15px;
    }
    .logo {
        height: 20px !important;
    }
    .logo-text {
        font-size: 20px !important;
    }
    .heading-text {
        font-size: 3vh;
    }
    .heading-sub-text {
        font-size: 2.5vh;
        font-weight: 500;
    }
    .sign-up-cont {
        padding: 15px !important;
    }
    .sign-up-cont-dark {
        padding: 15px !important;
    }
}

/* footer */
/* footer > p {
    position: absolute;
    bottom: 30;
} */