.settings-page {
    min-height: 100vh;
    width: 100vw;
}

.settings-body {
    display: flex;
    flex-direction: column;
    min-height: 90vh;
    padding: 30px 5% 100px;
    background-color: #42465105;
}

.settings-red-button {
    width: 270px;
    background-color: rgb(229, 75, 77);
    box-shadow: rgb(214 214 214) 0px 2px 5px;
    border-radius: 6px;
    padding: 5px 15px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 60px;
    border: 0;
}

.settings-blue-button {
    width: 270px;
    background-color: #2a88d0;
    box-shadow: rgb(214 214 214) 0px 2px 5px;
    border-radius: 6px;
    padding: 5px 15px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 60px;
    border: 0;
    color: #fff;
    font-weight: 900;
    font-size: 20px;
}

.settings-go-pro-button {
    width: 410px;
    max-width: 100%;
    background-color: #2a88d0;
    box-shadow: rgb(214 214 214) 0px 2px 5px;
    border-radius: 6px;
    padding: 5px 15px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 60px;
    color: #fff;
    font-weight: 900;
    font-size: 20px;
}

.settings-apps-cont {
    margin-left: -15px;
}

.settings-danger-cont {
    margin-top: 100px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgb(214 214 214) 0px 2px 5px 2px;
    border-radius: 1px;
    padding: 15px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.settings-red-text {
    font-size: 20px;
    font-family: Arial;
    font-weight: 700;
    color: #ff0000;
    margin-bottom: 50px;
}

.settings-app-cont > p {
    padding: 5px 15px;
    background-color: #424651;
    box-shadow: #424651 0px 2px 5px;
    border-radius: 12px;
    font-family: Arial,Helvetica,sans-serif;
    font-weight: 900;
    color: white;
    text-align: center;
}

.settings-white-text {
    color: white;
    font-weight: 900;
    font-size: 20px;
    margin-bottom: 0;
}

@media(max-width: 1000px) {

}