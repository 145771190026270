
.App {
  text-align: center;
}

h1, h2, h3, h4, h5, h6, p {
  font-family: Arial, Helvetica, sans-serif;
  color: #141212;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 900;
}