.header {
    /* background-color: #78C3FB; */
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2.5%;
    position: sticky;
    top: 0;
    /* box-shadow: #42465160 0px 1px 2px; */
}

.logo {
    height: 32px;
    color: white;
    cursor: pointer;
    margin-right: 5px;
}

.logo-text {
    font-size: 32px;
    color: #424651;
    cursor: pointer;
    font-family: Monaco;
    text-decoration: none;
}