.ia-ce-page {
    min-height: 100vh;
    width: 100vw;
}

.ia-ce-body {
    min-height: 92vh;
    width: 100vw;
    display: flex;
    flex-wrap: wrap;
    background-color: #42465105;
}

.ia-ce-body-right {
    width: calc(100vw - 60px);
    display: flex;
    flex-direction: column;
}

.ia-ce-header-text-30 {
    margin: 30px 30px 0;
    font-weight: 900;
}

.ia-red-text {
    color: rgb(229, 75, 77);
}

.ie-ce-top-section {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.ie-ce-iframe-cont {
    width: 30vw;
    min-width: 465;
    margin: 30px;
}

.ie-ce-iframe {
    max-width: 70vw;
}

.ie-ce-instructions-cont {
    width: calc(70vw - 180px);
    min-width: 260px;
    margin: 30px;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 30px;
    border-radius: 6px;
    box-shadow: #424651 0px 1px 3px;
}

.ia-ce-input-cont {
    width: 100%;
    margin: 30px;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 30px;
    border-radius: 6px;
    box-shadow: #424651 0px 1px 3px;
}

.ia-ce-input-edit-cont {
    width: 100%;
    margin: 30px;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 30px;
    border-radius: 6px;
    box-shadow: #424651 0px 1px 3px;
}

.ia-ce-header-text {
    font-weight: 900;
}

.ia-ce-create-input {
    width: 100%;
    border: #42465160 2px solid;
    border-radius: 6px;
    background-color: transparent;
    padding: 10px 20px;
    outline: 0;
}

.ia-ce-input {
    border: 0;
    border: #42465160 2px solid;
    border-radius: 6px;
    background-color: transparent;
    padding: 10px;
    outline: 0;
}

.ia-ce-create-button {
    background-color: #424651;
    box-shadow: rgb(214 214 214) 0px 2px 5px;
    border-radius: 6px;
    padding: 5px 30px;
    text-align: center;
    cursor: pointer;
}

.ia-ce-show-apis-button {
    background-color: #78c3fb;
    box-shadow: rgb(214 214 214) 0px 2px 5px;
    border-radius: 6px;
    padding: 5px 30px;
    text-align: center;
    cursor: pointer;
}

.ia-ce-edit-button {
    background-color: rgb(229, 75, 77);
    box-shadow: rgb(214 214 214) 0px 2px 5px;
    border-radius: 6px;
    padding: 5px 30px;
    text-align: center;
    cursor: pointer;
}

.ia-ce-delete-button {
    background-color: rgb(229, 75, 77);
    box-shadow: rgb(214 214 214) 0px 2px 5px;
    border-radius: 6px;
    padding: 5px 30px;
    text-align: center;
    cursor: pointer;
}

.ia-ce-white-text {
    color: white;
    font-weight: 900;
    font-size: 20px;
}

.white-text {
    color: white;
}

.ia-api-request {
    padding: 20px;
    background-color: #424651;
    box-shadow: #424651 0px 2px 5px;
    border-radius: 12px;
    font-family: Arial,Helvetica,sans-serif;
    font-weight: 900;
    color: white;
    margin-bottom: 0;
}

.mr-30 {
    margin-right: 30px;
}

@media(max-width: 1000px) {
    .ie-ce-instructions-cont {
        padding: 10px !important;
    }
    .ia-ce-input-cont {
        padding: 10px !important;
    }
    
    .ia-ce-input-edit-cont {
        padding: 10px !important;
    }
}