.ia-help-page {
    min-height: 100vh;
    width: 100vw;
}

.ia-help-body {
    min-height: 92vh;
    width: 100vw;
    display: flex;
    background-color: #42465105;
}

.ia-help-input-cont {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 60px;
    margin: 10%;
    border-radius: 6px;
    box-shadow: #424651 0px 1px 3px;
}

.ia-help-header-text {
    font-weight: 900;
}

.ia-help-input {
    width: 80%;
    border: 0;
    border-bottom: #42465160 3px solid;
    background-color: transparent;
    padding: 20px;
    outline: 0;
}

.ia-help-submit-button {
    width: 30%;
    background-color: rgb(229, 75, 77);
    box-shadow: rgb(214 214 214) 0px 2px 5px;
    border-radius: 6px;
    padding: 5px 30px;
    text-align: center;
    cursor: pointer;
}

.ia-help-white-text {
    color: white;
    font-weight: 900;
    font-size: 20px;
}



@media(max-width: 1000px) {

}