.dash-page {
    min-height: 100vh;
    width: 100vw;
}

.dash-body {
    min-height: 90vh;
    padding: 30px 5% 100px;
    background-color: #42465105;
}

.dash-create-button {
    width: 270px;
    background-color: rgb(229, 75, 77);
    box-shadow: rgb(214 214 214) 0px 2px 5px;
    border-radius: 6px;
    padding: 5px 15px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 60px;
}

.dash-go-pro-button {
    width: 200px;
    background-color: #64a2d0;
    box-shadow: rgb(214 214 214) 0px 2px 5px;
    border-radius: 6px;
    padding: 5px 15px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 60px;
    color: #fff;
    font-weight: 900;
    font-size: 20px;
}

.dash-apps-cont {
    margin-left: -15px;
}

.dash-app-cont {
    height: 20vw;
    min-height: 150px;
    width: 20vw;
    min-width: 150px;
    margin: 15px;
    background-image: url('../../assets/database-icon.png');
    background-color: rgb(255, 255, 255);
    box-shadow: rgb(214 214 214) 0px 2px 5px 2px;
    border-radius: 1px;
    padding: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dash-app-cont > p {
    padding: 5px 15px;
    background-color: #424651;
    box-shadow: #424651 0px 2px 5px;
    border-radius: 12px;
    font-family: Arial,Helvetica,sans-serif;
    font-weight: 900;
    color: white;
    text-align: center;
}

.dash-white-text {
    color: white;
    font-weight: 900;
    font-size: 20px;
}

@media(max-width: 1000px) {

}