.ia-header {
    background-color: white;
    height: 8vh;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    padding: 0 5%;
    box-shadow: #42465160 0px 0px 7px 1px;
}

.ia-logo {
    height: 32px;
    cursor: pointer;
    margin-right: 5px;
}

.ia-logo-text {
    font-size: 32px;
    color: white;
    cursor: pointer;
    font-family: Monaco;
    text-decoration: none;
}

.ia-header-input {
    border: 0;
    border: #42465160 1px solid;
    background-color: white;
    border-radius: 6px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding: 5px 10px;
    outline: 0;
    margin-left: 20px;
}

.ia-header-save-button {
    background-color: #424651;
    border-radius: 6px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 6px 10px;
    margin-right: 15px;
    cursor: pointer;
}

.ia-header-save-text {
    font-family: Arial,Helvetica,sans-serif;
    font-weight: 900;
    color: white;
}