.ia-sidebar {
    width: 60px;
    background-color: #424651;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    padding-top: 30px;
    
}

.ia-ps {
    position: sticky;
    top: 30px;
}

.ia-icon {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 45%;
    cursor: pointer;
}

.ia-icon-chosen {
    color: #78c3fb;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 45%;
    cursor: pointer;
}

.ia-white-sidebar-text {
    color: white;
    font-size: 16px;
    font-weight: 900;
    font-family: Monaco;
}