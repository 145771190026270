.dash-header {
    background-color: white;
    height: 8vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5%;
    box-shadow: #42465160 0px 0px 7px 1px;
}

.dash-logo {
    height: 32px;
    cursor: pointer;
    margin-right: 5px;
}

.dash-logo-text {
    font-size: 32px;
    color: white;
    cursor: pointer;
    font-family: Monaco;
    text-decoration: none;
}

.dash-logout-button {
    background-color: #424651;
    box-shadow: #424651 0px 2px 2px;
    border-radius: 6px;
    border-width: 0;
    padding: 5px 10px;
    margin-right: 15px;
    cursor: pointer;
}

.dash-logout-text {
    font-family: Arial,Helvetica,sans-serif;
    font-weight: 900;
    color: white;
}

.dash-head-settings-button {
    margin-right: 45px;
}

.dash-head-gear-icon {
    font-size: 40px;
    cursor: pointer;
}

@media (max-width: 500px) {
    .dash-head-settings-button {
        margin-right: 30px;
    }
}